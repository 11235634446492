<template>
  <div class="row">
    <div class="col-md-8">
      <dash-board-form :model="model">
      </dash-board-form>
    </div>
    <!-- <div class="col-md-4">
      <user-card :user="user"></user-card>
    </div> -->
  </div>
</template>
<script>
import DashBoardForm from './Profile/DashBoardForm';
import UserCard from './Profile/UserCard'
export default {
  components: {
    DashBoardForm,
    UserCard
  },
  data() {
    return {
      model: {
        nickName: 'mike',
        areaName: 'BIT',
        company: 'Creative Code Inc.',
        email: 'mike@email.com',
        username: 'michael23',
        firstName: 'Mike',
        lastName: 'Andrew',
        address: 'Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09',
        city: 'Melbourne',
        country: 'Australia',
        about: 'Lamborghini Mercy, Your chick she so thirsty, I\'m in that two seat Lambo.'
      },
      user: {
        fullName: 'Mike Andrew',
        title: 'Ceo/Co-Founder',
        description: `Do not be scared of the truth because we need to restart the human foundation in truth And I love you like Kanye loves Kanye I love Rick Owens’ bed design but the back is...`,
      }
    }
  }
}
</script>
<style></style>
